import { GatsbyBrowser } from "gatsby";

// Workaround for scroll issues when using Link component on Safari
// See: https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
    prevRouterProps,
	routerProps: { location },
    pathname,
	getSavedScrollPosition,
}) => {
	const currentPosition = getSavedScrollPosition(location);

	setTimeout(() => {
		window.scrollTo(...(currentPosition || [0, 0]));
	}, 100);

	return false;
};